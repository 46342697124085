[data-theme="dark"] {
  --font-color: #eee;
  --background-color: #333;
}

body,
.card {
  background-color: var(--background-color) !important;
  color: var(--font-color) !important;
}

.nav-link,
a,
a:active,
a:hover,
i {
  color: var(--font-color) !important;
}

.tech-link {
  color: black !important;
}

.card {
  border-color: var(--font-color);
}
