:root {
  /* content background */
  --main-bg: #f1eeec;
  /* about me bg */
  --accent-bg: white;
  /* content text, buttons, nav hover */
  --first: #4a4ccc;
  /* fonts */
  --main-font: "Open Sans", sans-serif;
  --header-font: "Outfit", sans-serif;
  --nav-font: "Montserrat", sans-serif;
}

body {
  background-color: var(--main-bg);
  font-family: var(--main-font);
  color: var(--first);
  padding: 0;
}

/* navigation */
nav {
  font-family: var(--nav-font);
  text-transform: uppercase;
  border-bottom: 3.5px solid black;
  transition: all 275ms ease;
}

nav a:hover {
  background-image: linear-gradient(var(--first), var(--first));
  background-size: 100% 40%;
  background-position: center bottom 2%;
  background-repeat: no-repeat;
  display: inline-block;
  filter: brightness(200%);
}

/* homepage */
#container {
  margin: auto;
  width: 50%;
}

#window,
#laptop,
#laptop-tool-tip,
#webcam {
  height: 400px;
  width: 500px;
  background-repeat: no-repeat;
  padding: 0;
}

#webcam {
  position: absolute;
  filter: saturate(2);
  clip-path: polygon(16% 0%, 79% 0%, 85% 55%, 83% 55%, 73% 75%, 15% 90%);
  background-position: center 6px, center 6px;
  background-size: 75% 75%;
  z-index: -1;
  resize: none;
}

#laptop {
  position: absolute;
  transform: skew(-20deg, 10deg);
  z-index: 1;
  background-size: 96px 74px;
  background-position: 404px 212px;
  filter: saturate(0.3);
}

#laptop-tool-tip {
  position: absolute;
  z-index: 3;
  clip-path: polygon(94% 83%, 76% 77%, 80% 60%, 99% 64%);
}

#window {
  position: relative;
  background-position: center center, center center;
  background-size: 100%, 100%;
  z-index: 1;
}

/* icons */
a,
.nav-link,
a:active {
  color: black;
}

i {
  font-size: 50px;
  align-items: center;
}

.tech-icon {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

i:hover,
a:hover {
  color: var(--first) !important;
}

.inactive {
  opacity: 0.35;
}

/* icon animation */
.spin-animated {
  animation: spin 0.5s 1 cubic-bezier(0.85, 0.01, 0.25, 1);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* work and resume sections */
button {
  border: none !important;
}

button:hover {
  background-color: var(--first) !important;
}

#resume-download {
  position: absolute;
  right: 0;
}

#headshot {
  background: linear-gradient(to right, rgb(181, 221, 255), rgb(217, 152, 255));
  width: 300px;
}

/* laptop popover edits */
#popover-contained {
  bottom: 28px !important;
}

.popover .popover-arrow::after,
.popover .popover-arrow::before {
  bottom: -6px;
}

.popover-header {
  color: black;
}
